import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import { Route, Routes } from "react-router-dom";
import HomePage from "./routes/HomePage";
import PageNotFound from "./components/PageNotFound";
import Services from "./routes/Services";
import Header from "./components/Header";
import CopyRight from "./components/CopyRight";
import ContactPage from "./routes/ContactPage";
import AboutPage from "./routes/AboutPage";
import CareerPage from "./routes/CareerPage";
import ContactResult from "./components/ContactResult";
import CareerResult from "./components/CareerResult";
import CommingSoon from "./components/CommingSoon";
import OurVentures from "./components/OurVentures";
import TermsAndConds from "./routes/TermsAndConds";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import Cancellation from "./routes/Cancellation";
import Declearation from "./routes/Declearation";
import AccountDeleteForm from "./routes/AccountDeleteForm";
import AccountDeleteApplications from "./routes/AccountDeleteApplications";



function App() {

  return (
    <>
    <Header/>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/services" element={<Services/>} />
      <Route path="/contact" element={<ContactPage/>}/>
      <Route path="/about" element={<AboutPage/>}/>
      <Route path="/career" element={<CareerPage/>}/>
      <Route path="/ventures" element={<OurVentures/>}/>

      <Route path="/contact-result" element={<ContactResult/>}/>
      <Route path="/career-result" element={<CareerResult/>}/>
      <Route path="/account/delete/form" element={<AccountDeleteForm/>}/>
      <Route path="/account/delete/view" element={<AccountDeleteApplications/>}/>

      <Route path="/comming-soon" element={<CommingSoon/>}/>

      <Route path="*" element={<PageNotFound />} />
      <Route path="/legal-documents/terms-and-conditions" element={<TermsAndConds />} />
      <Route path="/legal-documents/privacy-and-policy" element={<PrivacyPolicy />} />
      <Route path="/legal-documents/cancellation-and-refunds" element={<Cancellation />} />
      <Route path="/legal-documents/declearation" element={<Declearation />} />
    </Routes>
    <hr className="mx-3 border-2 border-dark opacity-25"/>
    <CopyRight />
    </>
  );
}

export default App;
