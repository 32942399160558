import axios from "axios";
import React, { useEffect, useState } from "react";
import { SERVER_URL } from "../constant";
import { BsCheckCircleFill } from "react-icons/bs";

function AccountDeleteForm() {
  const [appName, setAppName] = useState("");
  const [userId, setUserId] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  async function deleteApplication(e) {
    e?.preventDefault();
    if (confirm === false) {
      setAlertOpen(true);
      setAlertMessage("Please confirm your application");
      return null;
    }
    if (appName?.length === 0) {
      setAlertOpen(true);
      setAlertMessage("Please select your app service");
      return null;
    }
    if (userId?.length === 0) {
      setAlertOpen(true);
      setAlertMessage("Please enter your user id");
      return null;
    }

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/account/send/account/delete/application`,
        method: "post",
        data: {
          appName,
          userId,
        },
      });
      setSuccess(true);
    } catch (error) {
      setAlertOpen(true);
      setAlertMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setAlertOpen(false);
    }, 5000);
  }, [alertOpen]);

  if (success) {
    return (
      <div className="pt-5">
        <div className="container pt-5 rounded-3">
          <h2 className="mb-5">Welcome To AppNest Products Page</h2>
          <div className="text-center p-5">
            <BsCheckCircleFill size={"50px"} className="mb-3 text-success" />
            <h2>Application Submitted</h2>
            <p style={{ fontWeight: "700", paddingTop: "5px" }}>
              Thank you for your confirmation to delete your permanent Account
              Your account will delete after 90days.
            </p>
            <p style={{ marginTop: "10px", fontWeight: "500" }}>
              Thank you for using{" "}
              <span style={{ fontWeight: "700" }}>
                {appName?.replace("-", " ")?.toUpperCase()}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-5">
      <div className="container pt-5 rounded-3">
        <h2 className="mb-5">Welcome To AppNest Products Page</h2>
        <div className="border py-3 px-4 rounded-3 shadow-sm">
          {alertOpen === true ? (
            <div class="alert alert-danger" role="alert">
              {alertMessage}
            </div>
          ) : (
            ""
          )}
          <p style={{ fontSize: "20px", fontWeight: "700" }}>
            Account Deletion Form
          </p>
          <form onSubmit={deleteApplication}>
            <div className="w-100">
              <label for="exampleInputEmail1" className="mb-2">
                Select Your App & Web Name{" "}
                <span style={{ fontWeight: "500" }}>"Our Ventures"</span>
              </label>
              <select
                class="form-control"
                onChange={(e) => setAppName(e?.target?.value)}
              >
                <option value={""}>Select Your App</option>
                <option value={"appnest-pos"}>Appnest POS</option>
                <option value={"appnest-people"}>Appnest People</option>
                <option value={"appnest-spa"}>Appnest Spa</option>
                <option value={"sn-pharmacy"}>SN Pharmacy</option>
                <option value={"karter"}>Karter</option>
                <option value={"karter-owner"}>Karter Owner</option>
                <option value={"karter-driver"}>Karter Driver</option>
                <option value={"jetdrop-owner"}>Jetdrop Owner</option>
                <option value={"jetdrop-driver-app"}>Jetdrop Driver App</option>
                <option value={"jetdrop-:bike,taxi,auto&cabs"}>
                  Jetdrop : Bike, Taxi, Auto & Cabs
                </option>
                <option value={"vidyalaya-all-in-one-school-app"}>
                  Vidyalay All In One School App
                </option>
                <option value={"vidyalaya-student"}>Vidyalay Student</option>
                <option value={"vidyalaya-stuff"}>Vidyalay Stuff</option>
                <option value={"colorcode-food-delivary-app"}>
                  ColorCode Food Delivary App
                </option>
                <option value={"colorcode-all-in-one-app"}>
                  ColorCode All In One App
                </option>
                <option value={"quick-pay"}>Quick Pay</option>
                <option value={"demo"}>Demo</option>
              </select>
            </div>
            <div class="form-group mt-4">
              <label for="exampleInputEmail1" className="mb-2">
                Your UserID
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter UserID"
                onChange={(e) => setUserId(e?.target?.value)}
              />
            </div>
            <div class="form-check mt-4">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
                onChange={(e) => setConfirm((prev) => !prev)}
              />
              <label class="form-check-label text-danger" for="defaultCheck1">
                Confirm Your Permanent Account Deletion
              </label>
            </div>
            {loading === true ? (
              <p>Please wait...</p>
            ) : (
              <button type="submit" class="btn btn-primary mt-4">
                Submit
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default AccountDeleteForm;
