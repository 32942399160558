import axios from "axios";
import React, { useEffect, useState } from "react";
import { SERVER_URL } from "../constant";

function AccountDeleteApplications() {
  const [careerResultData, setCareerResultData] = useState();
  useEffect(() => {
    (async () => {
      const response = await axios.get(
        `${SERVER_URL}/api/v1/account/get/account/delete/applications`
      );
      console.log(response.data.data);
      setCareerResultData(response.data.data);
    })();
  }, []);

  return (
    <div className="py-5 mt-5 px-2">
      <h1 className="fw-bold text-center my-3 pb-3">Account Delete Application List</h1>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Application Date</th>
            <th scope="col">Service</th>
            <th scope="col">User ID</th>
          </tr>
        </thead>
        <tbody>
          {careerResultData?.map((item, index) => {
            return (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{new Date(item?.applicationDate)?.toLocaleDateString()}</td>
                <td>{item?.appName?.replace("-", " ")?.toUpperCase()}</td>
                <td>{item?.userId}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default AccountDeleteApplications;
