import React from 'react'

function Declearation() {
  return (
    <div className="bg-blac pt-5">
      <div className=" container pt-5 text-cente rounded-3 ">
        <h1 className="display-4 lh-1 fw-normal mb-5">Declearation</h1>
      </div>
      <div className="container">
        The following terms and conditions will be deemed to have been accepted by the User on usage of the website www.appnestit.com. You are requested to read them carefully before you use the services of this site. 
        <br/><br/><br/>
        The term User shall refer to the user who is browsing the site. The term AppNest Information Technology Private Limitedshall refer to AppNest Information Technology Private LimitedInformation Technology Private Limited. The term Site refers to <b>www.appnestit.com</b> owned and monitored by AppNest. 
        <br/><br/>
        By using the Site, you agree to follow and be bound by the following terms and conditions concerning your use of the Site. AppNestmay revise the Terms of Use at any time without notice to you. Areas of the Site may have different terms of use posted. If there is a conflict between the Terms of Use and terms of use posted for a specific area of the Site, the latter shall have precedence with respect to your use of that area of the Site. 
        AppNest Information Technology Private Limitedmay terminate User's access at any time for any reason. The provisions regarding to disclaimer of warranty, accuracy of information, and indemnification shall survive such termination. AppNest Information Technology Private Limitedmay monitor access to the Site. 
        <br/><br/>
        All content present on this site is the exclusive property of AppNest Information Technology Private Limited The software, text, images, graphics, video and audio used on this site belong to AppNest Information Technology Private Limited No material from this site may be copied, modified, reproduced, republished, uploaded, transmitted, posted or distributed in any form without prior written permission from AppNest Information Technology Private Limited All rights not expressly granted herein are reserved. Unauthorized use of the materials appearing on this site may violate copyright, trademark and other applicable laws, and could result in criminal or civil penalties. AppNest Information Technology Private Limitedis a registered trademark of AppNest Information Technology Private LimitedInformation Technology Private Limited. This trademark may not be used in any manner without prior written consent from AppNest Information Technology Private Limited Information Technology Private Limited. 
        <br/><br/>
        AppNest Information Technology Private Limiteddoes not make any warranties, express or implied, including, without limitation, those of merchantability and fitness for a particular purpose, with respect to any information, data, statements or products made available on the Site. 
        <br/><br/>
        The Site, and all content, materials, information, software, products and services provided on the Site, are provided on an "as is" and "as available" basis. AppNest Information Technology Private Limitedexpressly disclaims all warranties of any kind, whether express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose and non-infringement. 
        <br/><br/>
        AppNest Information Technology Private Limitedshall have no responsibility for any damage to User's computer system or loss of data that results from the download of any content, materials, information from the Site.
        <br/><br/>
        AppNest may change or discontinue any aspect of its website at any time, including, its content or features. AppNest Information Technology Private Limited reserves the right to change the terms and conditions applicable to use of the Site. Such changes shall be effective immediately upon notice, which shall be placed on the Site. 
        <br/><br/>
        In no event wil AppNestbe liable for damages of any kind, including without limitation, direct, incidental or consequential damages (including, but not limited to, damages for lost profits, business interruption and loss of programs or information) arising out of the use of or inability to use AppNest's website, or any information provided on the website, or in the Products any claim attributable to errors, omissions or other inaccuracies in the Product or interpretations thereof. Some jurisdictions do not allow the limitation or exclusion of liability. Accordingly, some of the above limitations may not apply to the User. 
        <br/><br/>
        User agrees to indemnify, defend and hold AppNest Information Technology Private Limitedharmless from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, arising out of or relating to any misuse by the User of the content and services provided on the Site. 
        <br/><br/>
        The information contained in the Site has been obtained from sources believed to be reliable. AppNest Information Technology Private Limiteddisclaims all warranties as to the accuracy, completeness or adequacy of such information. 
        <br/><br/>
        AppNest Information Technology Private Limitedmakes no warranty that: (a) the Site will meet your requirements; (b) the Site will be available on an uninterrupted, timely, secure, or error-free basis; (c) the results that may be obtained from the use of the Site or any services offered through the Site will be accurate or reliable. 
        <br/><br/>
        The User's right to privacy is of paramount importance to AppNest Information Technology Private Limited Any information provided by the User will not be shared with any third party. AppNest Information Technology Private Limitedreserves the right to use the information to provide the User a more personalized online experience. 
        <br/><br/>
        The Site provides links to web sites and access to content, products and services from third parties, including users, advertisers, affiliates and sponsors of the Site. You agree that AppNestis not responsible for the availability of, and content provided on, third party web sites. The User is requested to peruse the policies posted by other web sites regarding privacy and other topics before use. AppNestis not responsible for third party content accessible through the Site, including opinions, advice, statements and advertisements, and User shall bear all risks associatec with the use of such content. AppNestis not responsibl for any loss or damage of any sort User may incur from dealing with any third party. 
        <br/><br/>
        AppNest shares its bank details only through an identified mail id care@AppNestit.comInformation Technology Private Limitedcom. As a normal course of business AppNestnever authorizes its employees to share its bank details with our customers directly. In case, you receive any notification on addition/change/update of bank details of AppNestfrom any other email id, please write to us at care@AppNestit.com Information Technology Private Limited.
      </div>
    </div>
  )
}

export default Declearation
