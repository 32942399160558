import React from 'react'

function Cancellation() {
  return (
    <div className="bg-blac pt-5">
      <div className=" container pt-5 text-cente rounded-3 ">
        <h1 className="display-4 lh-1 fw-normal mb-5">Cancellation And Refunds</h1>
      </div>
      <div className="container">
        CANCELLATION AND REFUND POLICY 
        <br/><br/>
        (FOR OUTSIDE INDIA CUSTOMERS) 
        <br/><br/>
        Last Updated: April,03,2024 
        <br/><br/>
        We, AppNest Information Technology Private Limited
        <br/><br/>
        ("We", "Us", "Our", “Company”), is in the business of providing a cloud-based point of sales software application under the brand name "AppNest " for Collage and School together with optionally integrated services such as mobile or card payments, inventory management, deals and discounts, ordering and delivery apps, table booking apps, suppliers' marketplace, analytics apps, etc. using a programmabl API architecture. The Company through such point of sale software application and websites (together referred to as "AppNest Platforms") also provides end to end integration of services for Collage and school management to various restaurants and food outlets. The Company has created and owns the AppNest Platforms and licensed the use of the same by way of various subscription model for a Subscription Fee. 
        <br/><br/>
        The subscription to the AppNest Platform and other value added services ("AppNest Subscription") offered by us is subject to our Terms of Services that isagreed by you (or anyone who accesses and uses the AppNest Platforms shall be referred to as "You", "Your" or "User" as the context may require) at the time ofsubscription. You may select a AppNest subscription plan of your choice and make payment as per the required payment method(s) available for different AppNest subscription plans. 
        <br/><br/>
        Fees 
        <br/><br/>
        You are required to pay subscription fee as per the AppNest Subscription plan subscribed by you ("Subscription Fee"). The Subscription Fee for AppNest Subscription as stated in various subscription plans is non-refundable except as expressly set forth in these cancellation and refund terms (hereinafter referred to as "Cancellation & Refund Policy"). Taxes may apply on the subscription fee. Your paid AppNest Subscription will be activated only upon successful payment of the Subscription Fee made through the AppNest Platforms as per the applicable subscription plan chosen by you. 
        <br/><br/>
        Subscription Cancellation & Refund 
        <br/><br/>
        You may cancel your paid AppNest Subscription any time by visiting Your Account and adjusting your subscription settings on any of the AppNest Platforms. If you cancel the AppNest Subscription within 15 business days of signing up for such paid AppNest Subscription, we will issue a credit note to you and refund your full Subscription Fee, provided that we may charge you (or withhold from your refund) the value of AppNest benefits used by you and your account during this 15 business day period by issuing a partial credit note to you. If you cancel the annual AppNest Subscription at any other time, we will raise a credit note and refund your Subscription Fee only to the extent of your usage of the subscription plan of our AppNest Platform since the payment of the Subscription Fee. 
        <br/><br/>
        If you cancel the monthly AppNest Subscription, the subscription fee will be refunded only if you have not made any usage or taken advantage any of the AppNest benefits since payment of your Subscription Fee. 
        <br/><br/>
        Revisions 
        <br/><br/>
        We may in our discretion change the Terms of Service as well as this Cancellation & Refund Policy, or any aspect of AppNest Subscription, without notice to you. If any change to these terms is found invalid, void, or for any reason unenforceable, that change is severable and does not affect the validity and enforceability of any remaining changes or conditions. YOUR CONTINUED SUBSCRIPTION AFTER WE CHANGE THESE TERMS CONSTITUTES YOUR ACCEPTANCE OF THE CHANGES. IF YOU DO NOT AGREE TO ANY CHANGES, YOU MUST CANCEL YOUR SUBSCRIPTION. 
        <br/><br/>
        Termination by Us 
        <br/><br/>
        We may terminate your AppNest Subscription at our discretion without notice. If we do so, we will issue a credit note to you and give a prorated refund based on the remaining term of your then subscription term, whether annual or monthly. However, we will not give any refund for termination related to conduct that we determine, in our discretion, violates the Terms of Service or any applicable law, involves fraud or misuse of the AppNest Subscription, or is harmful to our interests or another user. Our failure to insist upon or enforce your strict compliance with the Terms of Services or this Cancellation & Refund Policy will not constitute a waiver of any of our rights. 
        <br/><br/>
        Limitation of Liability 
        <br/><br/>
        IN ADDITION TO OTHER LIMITATIONS AND EXCLUSIONS IN CONDITIONS OF USE AND SALE, IN NO EVENT WILL WE OR OUR DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR OTHER REPRESENTATIVES BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, ARISING OUT OF OR RELATED TO AppNest PLATFORMS. OUR TOTAL LIABILITY, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, WILL NOT EXCEED THE LAST SUBSCRIPTION FEE YOU PAID. THESE EXCLUSIONS AND LIMITATIONS OF LIABILITY WILL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW AND WILL SURVIVE CANCELLATION OR TERMINATION OF YOUR AppNest SUBSCRIPTION.
      </div>
    </div>
  )
}

export default Cancellation
